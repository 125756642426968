import React from 'react'
import { useText } from '../../contexts/TextContext';
import { Cart, CartItem } from '../../models/Cart'
import { Order } from '../../models/Order'
import { sumCart } from '../../utils/shared/cart';
import { printAmount } from '../../utils/shared/converters';

interface Props {
    order: Cart | Order,
    changeQuantity?: (item: CartItem, newQuantity: number) => void
}

export default function LineItems(props: Props) {
    const { order, changeQuantity } = props;
    const text = useText();
    const { sum, discount, tip } = sumCart(order);

    return (<>
        {order.items.map((item, ix) => {
            const lineTotal = item.itemPrice * item.quantity;
            const canBeLowered = item.quantity > 1;

            return <div key={ix} className="order-item">
                <div className="name">{item.itemName}</div>
                <div className="other">{printAmount(item.itemPrice)}</div>
                <div className="other">
                    {changeQuantity && <button className={`btn ${canBeLowered ? 'btn-primary' : 'btn-danger'}`}
                        onClick={() => changeQuantity(item, item.quantity - 1)}>
                        <i className={`bi ${canBeLowered ? 'bi-chevron-down' : 'bi-trash'}`} />
                    </button>}
                    <span className='ms-2 me-2 bold'>{item.quantity}</span>
                    {changeQuantity && <button className='btn btn-primary'
                        onClick={() => changeQuantity(item, item.quantity + 1)}>
                        <i className={`bi bi-chevron-up`} />
                    </button>}
                </div>
                <div className="other">{printAmount(lineTotal)}</div>
            </div>
        })}
        {!!discount && <div className="order-item mt-4">
            <div className="name">{text.discount}</div>
            <div className="other">{printAmount(-discount)}</div>
        </div>}
        {!!order?.tip && <div className="order-item mt-4">
            <div className="name">{`${text.leaveTip} (${order.tip} %)`}</div>
            <div className="other">{printAmount(tip)}</div>
        </div>}
        <div className="total-line mt-4">
            <div className="name">{text.sum}</div>
            <div className="other">{printAmount(sum)}</div>
        </div>
    </>
    )
}
