import { getPersistableQueryString } from "../hooks/useQueryParams";
import Restaurant from "../models/Restaurant";

export const getRestaurantPath = (restaurant: Restaurant, path: string) => {
    let resultPath = path;

    if (!resultPath.includes('?')) {
        const queryParams = getPersistableQueryString();
        resultPath = `${resultPath}?${queryParams}`;
    }

    return resultPath;
}
