
export const HIDE_FUTURE_ORDERS_MINUTES = 60;

export enum PAYMENT_TYPES {
    SWISH = 'swish',
    WORLDLINE = 'worldline',
    CARD = 'card',
    STRIPE_TERMINAL = 'stripeTerminal',
}

export enum QUERY_PARAMS {
    EMAIL_SIGNUP_FINISH = 'finishEmailSignup',
    RESTAURANT = 'restaurant',
    DEBUG = 'debug',
}
