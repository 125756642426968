const text = {
    name: 'Name',
    officialName: 'Official Name',
    organizationNumber: 'Organization Number',
    adddress: 'Address',
    officialAdddress: 'Official Address',
    contactEmail: 'Contact Email',
    contactPhone: 'Contact Phone',
    addToCart: 'Add to Cart',
    products: 'Products',
    checkout: 'Checkout',
    backToOrder: 'Back to Order',
    sum: 'Sum',
    swish: 'Swish',
    card: 'Card',
    or: 'or',
    notFound: 'Not Found',
    notAuthorized: 'Not Authorized',
    notAuthorizedDescription: 'You are not authorized to view this page.',
    switchUser: 'Switch User',
    category: 'Category',
    minute_short: 'm',
    confirm: 'Confirm',
    processing: 'Processing',
    enable: 'Enable',
    disable: 'Disable',
    and: 'and',
    restaurantIsDisabled: 'Restaurant is disabled at this time',
    otherLanguage: 'Svenska',
    tipLabel: 'Just the tip',
    tip: 'Tip',
    tips: 'Tips',
    leaveTip: 'Leave a tip',
    superAdmin: 'Super Admin',
    admin: 'Admin',
    staff: 'Staff',
    myOrders: 'My Orders',
    details: 'Details',
    edit: 'Edit',
    update: 'Update',
    type: 'Type',
    chatWithUs: 'Chat with us',
    public: 'Public',
    prepare: 'Prepare',

    errorDefault: 'Ouch! This failed. Check with Johan and well friends.',
    errorInvalidProduct: 'The requested product in store is not. Choose other one you must.',
    errorMessaging: 'Failed to enable notifications',
    successDefault: 'Awesome! That worked.',

    cartEmptyText: 'Your cart is empty mate. Please check some of our sweet goodies!',
    swishOnOtherDevice: 'Swish on other device',
    paymentFailed: 'Your order payment failed.',
    tryAgain: 'Please try again.',

    orderAccepted: 'We got your order. Thank you!',
    orderInQueue: 'Number of orders ahead of yours',
    orderReady: 'Your order is ready!',
    orderNumber: 'Order Number',
    retry: 'Retry',

    followTheStatus: 'This page automatically updates with the current status.',

    login: 'Login',
    logout: 'Logout',
    login_email: 'Email',
    login_password: 'Password',
    login_fail: 'The password is invalid or the user does not have a password.',
    login_fail_too_many_attempts: 'Access to this account has been temporarily disabled due to many failed login attempts. Try again later.',
    login_google: 'Login with Google',
    login_withPassword: 'Login with password',
    login_withEmail: 'Login with Email',
    login_magicLinkSent: 'Magic link sent to your email',
    login_finishEmailNoEmail1: 'It appears you started the login process on a different device.',
    login_finishEmailNoEmail2: 'If you want to log in on this device, please re-enter your email to verify your identity. If not, please use the magic link from your email on the device you wish to log in on.',
    login_invalidActionCode: 'The action code is invalid. This can happen if the code is malformed, expired, or has already been used.',
    login_emailMismatch: 'The email address is different from the one used in the previous step.',
    login_login_success: 'Yo yo yo, {name}! Welcome to the party 🎉',

    orderReset: 'System will reset shortly for the next customer',
    keepActive: 'Whaaat wait!',

    paid: 'Paid',
    todo: 'TODO',
    inProgress: 'In Progress',
    done: 'Done',

    dashboard: 'Dashboard',

    newProduct: 'New Product',
    save: 'Save',
    saving: 'Saving ...',
    saved: 'Saved',
    add: 'Add',
    archive: 'Archive',
    cancel: 'Cancel',
    back: 'Back',
    openingHours: 'Opening Hours',
    outsideOpeningHours: 'Outside Opening Hours',
    open: 'Open',
    closed: 'Closed',
    close: 'Close',
    openDescription: 'Use opening hours to decide if new orders can be accepted',
    closeDescription: 'Do not accept orders and show custom text below',
    customerContact: 'Customer contact',
    okey: 'Okey',
    delete: 'Delete',
    apply: 'Apply',
    closedMessage: 'Let your customers know why you are not accepting orders',
    deleteProduct: 'Delete Product',
    deleteProductConfirm: 'Are you sure you want to delete this product?',
    thisActionCannotBeUndone: 'This action cannot be undone.',
    color: 'Color',
    beta: 'beta',
    loadOlder: 'Load Older',
    confirmation: 'Confirmation',
    operations: 'Operations',
    comingSoon: 'Coming Soon',
    moveUp: 'Move Up',
    moveDown: 'Move Down',
    unarchive: 'Unarchive',

    // Order History
    changeState: 'Change State',
    markRefunded: 'Mark Refunded',
    areYouSureYouWantToMarkRefunded: 'Are you sure you want to mark this order as refunded?',

    // Confirm
    noEstimate: 'Please wait for the time estimate',
    estimateTime: 'Your order will be ready in',
    minute: 'minute',
    minutes: 'minutes',
    busy: 'Mark as busy',
    busyDescription: 'Are you sure you want to show expected waiting time to be at least 25 mintes from now?',
    orderQueueWaitTime: 'Order Queue Time',

    /// Receipt
    receipt: 'Receipt',
    receiptPrinted: 'Receipt was already printed',
    returnReceipt: 'Return Receipt',
    returnReceiptPrinted: 'Return Receipt was already printed',
    vat: 'VAT',
    totalVat: 'VAT sum',
    paidViaSwish: 'Paid via Swish',
    paidViaCard: 'Paid via Card',
    nameOfRegister: 'Name of cash register',
    electronicReceipt: 'electronic receipt',
    receiptCopy: 'copy',
    receiptReturn5Days: 'Moneys comes to your account within 5 business days',
    refunded: 'Refunded',

    /// Orders
    orders: 'Orders',
    history: 'History',
    analytics: 'Analytics',
    ordersTotal: 'Total Orders',
    salesTotal: 'Total Value Sold',
    setup: 'Setup',
    payByCard: "Pay by Card",
    byProceeding: 'By proceeding with payment, I accept the ',
    termsConditions: 'Terms & Conditions',
    privacyPolicy: 'Privacy Policy',

    /// Options
    options: 'Options',
    addOption: 'Add Option',
    addChoice: 'Add Choice',
    mandatory: 'Mandatory',
    choiceName: 'Choice Name',
    optionName: 'Option Name',
    choicePriceAdjustment: 'Price Adjustment',
    makeMandatory: 'Make Mandatory',
    makeNotMandatory: 'Make Not Mandatory',

    /// Staff
    restaurant: 'Restaurant',
    salesDate: 'Date of Sale',
    from: 'From',
    to: 'To',
    clearFilters: 'Clear All Filters',
    generateSIFile: 'Generate SI File',
    exportFilterRequired: 'To generate file, choose date range and NO other filter',
    estimatedTime: 'ETA',
    default: 'default',
    makeDefault: 'Make Default',
    makeNotDefault: 'Make Not Default',
    generatePdfReport: 'Generate pdf report',
    orderReport: 'Orders Report',
    generateReportInstruction: 'Generate SI File to enable pdf report',
    generateSkatteverketExportXML: 'Generate Skatteverket XML',

    // Features
    features: 'Features',
    estimateType: 'Estimate Type',
    queue: 'Queue Count',
    queueDescription: 'Customer is shown number of orders that are not done and are paid before theirs.',
    setByStaff: 'Set By Staff',
    setByStaffDescription: 'Customer is asked to wait for the Staff member to put in estimate for each order.',
    paymentMethods: 'Payment Methods',
    enabled: 'Enabled',

    checkoutText: 'Checkout Text',
    none: 'None',
    text: 'Text',
    textDescription: 'Text will be shown above payment button.',
    textConfirm: 'Confirm',
    textConfirmDescription: 'Text will have to be confirmed before order can be paid.',
    textMenuConfirmWeArePreparing: 'Order Crafting In Progress',
    textMenuConfirmDone: 'Order Crafting Done!',

    // Branding
    branding: 'Branding',
    logo: 'Logo',
    updateLogo: 'Update Logo',
    primary: 'Primary Color',
    bgPrimary: 'Primary Background',
    bgSecondary: 'Secondary Background',

    // Accounting
    accounting: 'Accounting',
    financialYearStart: 'Financial Year Start',
    accounts: 'Accounts',
    bankAccount: 'Bank Account',
    salesAccount: 'Sales Account',
    tipsAccount: 'Tips Account',

    // Deals
    deals: 'Deals',
    newDeal: 'New Deal',
    addDeal: 'Add Deal',
    code: 'Code',
    discount: 'Discount',
    discountCode: 'Discount Code',
    codeApplied: 'Code was applied',
    codeNotFound: 'Wrong cheat code',
    usedCount: 'Used Count',
    percentageCode: 'Percentage Code',
    stampCard: 'Stamp Card',
    howManyStampsNeeded: 'How many stamps needed to get one free',
    validProducts: 'Valid Products',
    collectX: 'Collect {X} get 1 free',
    rule: 'Rule',

    // My Benefits
    myBenefits: 'My Benefits',
    myBenefitsDescription1: 'Yo! Congrats on making the world a greener place with every delicious falafel you chow down.',
    myBenefitsDescription2: 'To show our love, we\'ve got some sweet perks lined up that\'ll make your eco-friendly meal experience even more satisfying. So go ahead, dig in and feel good about your impact on the planet!',
    refferalProgram: 'Referral Program',
    stampCards: 'Stamp Cards',

    // Pickup
    pickup: 'Pickup',
    asap: 'As soon as possible',
    today: 'Today',
    tomorrow: 'Tomorrow',
    chooseDate: 'Choose date',
    chooseTime: 'Choose time',
    noTimesAvailable: '😿 No times available, please choose another date',
    preorderNow: 'But you can still preorder pickup if you\'re a planning pro!',

    // Feedback
    feedback: 'Feedback',
    feedbackIsGift: 'Feedback is a gift',
    feedbackIsGiftDescription: 'Was there anything you liked or something that sucked?',
    iWantYou: 'I want YOU ... to let us know',
    thankYouForInsights: 'Thank you for your insights!',
    feedbackPublic: 'Feedback is public now',
    feedbackDiscord: 'Feedback is now piped straight to Discord',
    feedbackDontHold: 'Don\'t let that hold you back though! 🍳 Roast us as you were about to, so we can grow!',
    thankYouSoMuch: 'Thank you so much for doing this!',
    feedbackIfYouWould: 'If you would like to tell us more, join us here:',

    // Day Reports
    openCashRegister: 'Open cash register',
    closeCashRegister: 'Close cash register',
    areYouSureCloseRegister: 'Are you sure you want to close the cash register?',
    dayReports: 'Day Reports',
    xReport: 'X Day Report',
    zReport: 'Z Day Report',
    payments: 'Payments',
    sales: 'Sales',
    returns: 'Returns',
    totalSales: 'Total Sales',
    totalNetto: 'Total Netto',
    paymentType: 'Payment type',
    temporary: 'Temporary',
    printDate: 'Print Date',
    includingVATSh: 'Incl. VAT',
    excludingVATSh: 'Excl. VAT',
    receipts: 'Receipts',
    receiptCopies: 'Receipt coppies',
    openingCashBalance: 'Opening cash balance',
    cashWithdrawals: 'Cash withdrawals',
    grandTotalSalse: 'Grand total sales',
    grandTotalReturns: 'Grand total returns',
    grandTotalNetto: 'Grand total netto sales',

    // Offer
    offerExpired: "Oops! Looks like our offer has gone stale like yesterday's bread. But don't let that stop you from satisfying your hunger! Come on in and check out our delicious menu. Trust us, our food is still fresh and ready to please your taste buds. So, ditch that expired offer and join us for a meal that will make you forget all about it!",
    claimOffer: 'Claim offer',
    offerTextGeneratedByAIExcuseInperfections: 'Text generated by AI, excuse inperfections',

    // Audit
    auditLog: 'Audit Log',
    date: 'Date',
    data: 'Data',
    user: 'User',

    // Device
    device: 'Device',
    makeWorkstation: 'Make workstation',
    makeCardTerminal: 'Make card terminal',
    registerCardTerminal: 'Register card terminal',
    onlyDoneOnce: 'Only done once',
    connectTerminal: 'Connect terminal',
    terminalName: 'Terminal name',
    mustMatchNameFromRegistration: 'Must match name from registration',
    workstationName: 'Workstation name',
    noNameProvided: 'No name was provided',
    workstation: 'Workstation',
    active: 'Active',
    inactive: 'Inactive',
    showingAllOrders: 'Showing all orders',
    activeWorkstations: 'Active Workstations',
    moveTo: 'Move to',

    // Card
    followInstructionsOnTerminal: 'Follow instructions on payment terminal',
};

export default text;