import Menu from '../../components/customer/Menu'
import { CUSTOMER_NAV } from '../../constants/routes';
import { useText } from '../../contexts/TextContext'
import RestaurantLink from '../../components/RestaurantLink';
import CheckoutApplyCode from '../../components/customer/CheckoutApplyCode';
import { useCart } from '../../contexts/CartContext';
import CompanyFooter from '../../components/customer/CompanyFooter';
import CheckoutCart from '../../components/customer/CheckoutCart';
import CheckoutPayments from '../../components/customer/CheckoutPayments';
import { useState } from 'react';
import SchedulePickup from '../../components/customer/SchedulePickup';
import CheckoutTip from '../../components/customer/CheckoutTip';
import BusyIndicator from '../../components/customer/BusyIndicator';


export default function CheckoutPage() {
    const { cart } = useCart();
    const text = useText();
    const [pickupDate, setPickupDate] = useState<Date | null>(null);

    const isEmpty = !cart.items.length;
    if (isEmpty) {
        return <div className='streach'>
            <Menu />
            <div className="container">
                <div className="center flex-column pt-4 text-center text-primary">

                    <h2 >{text.cartEmptyText}</h2>
                    <div className="m-4" />
                    <RestaurantLink to={`/${CUSTOMER_NAV.PRODUCTS}`}>
                        <button className="btn btn-light">{text.products}</button>
                    </RestaurantLink>
                </div>
            </div>
        </div>
    }

    return (
        <div className='streach'>
            <Menu />
            <div className="container text-primary d-flex flex-column">
                <CheckoutCart />

                <SchedulePickup className='mt-5 mb-4' pickupDate={pickupDate} updatePickupDate={setPickupDate} />
                <div className='center justify-content-start mb-4'>
                    <span className='me-2'>{text.orderQueueWaitTime}</span>
                    <BusyIndicator showTime={true} />
                </div>
                <CheckoutTip className='mb-4' />
                <CheckoutApplyCode className=' mb-4' />

                <CheckoutPayments />

            </div>
            <CompanyFooter />
        </div>
    )
}
