import React from 'react'
import Menu from '../../components/customer/Menu'
import NeonText from '../../components/customer/NeonText';
import StampCards from '../../components/customer/StampCards';
import { useText } from '../../contexts/TextContext'

export default function MyBenefits() {
    const text = useText();
    return (
        <div className='streach'>
            <Menu />
            <div className='container text-primary'>
                <p>{text.myBenefitsDescription1}</p>
                <p>{text.myBenefitsDescription2}</p>

                <StampCards />


                <h2 className='mt-3'>{text.refferalProgram}</h2>
                <NeonText text={text.comingSoon} />
            </div>
        </div>
    )
}
