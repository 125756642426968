import { useEffect, useState } from 'react'
import { CUSTOMER_NAV } from '../../constants/routes';
import { useCart } from '../../contexts/CartContext';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { useText } from '../../contexts/TextContext';
import { useBackend } from '../../hooks/useBackend';
import { generateSwishAppLink } from '../../utils/swish';
import { usePublicTerminal } from '../../contexts/PublicTerminalContext';
import { PAYMENT_TYPES } from '../../utils/shared/constants';
import { useDB } from '../../contexts/FirebaseContext';
import { doc, onSnapshot } from 'firebase/firestore';
import { fromFirebaseDoc } from '../../utils/shared/firebase';
import { Order, OrderState } from '../../models/Order';
import { Navigate } from 'react-router-dom';
import { getRestaurantPath } from '../../utils/restaurant';

export default function CheckoutPaymentSwish() {
    const text = useText();
    const restaurant = useRestaurant();
    const [processing, setProcessing] = useState(false);
    const { initPayment, handleInitPaymentError, getQR } = useBackend();
    const { cart } = useCart();
    const { isPublicTerminal } = usePublicTerminal();
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [qrCodeOrderId, setQrCodeOrderId] = useState('');
    const [qrCodeOrder, setQrCodeOrder] = useState<Order | null>(null);
    const db = useDB();

    useEffect(() => {
        if (db && qrCodeOrderId && restaurant.id) {
            return onSnapshot(doc(db, "restaurants", restaurant.id, "orders", qrCodeOrderId), (doc) => {
                const newOrder = fromFirebaseDoc<Order>(doc)
                setQrCodeOrder(newOrder);
            });
        }
    }, [db, qrCodeOrderId, restaurant.id]);

    const handleSwish = async () => {
        try {
            setProcessing(true);
            const result = await initPayment(cart, restaurant.sysid, PAYMENT_TYPES.SWISH);
            const swishAppLink = generateSwishAppLink(result.orderId, restaurant, result.paymentId);

            window.location.href = swishAppLink;
        } catch (e) {
            handleInitPaymentError(e)
        }
        setProcessing(false);
    }

    const handleSwishQR = async () => {
        try {
            setProcessing(true);
            const result = await initPayment(cart, restaurant.sysid, PAYMENT_TYPES.SWISH);
            const imageBlob = await getQR(result.paymentId);
            const imageUrl = URL.createObjectURL(imageBlob);
            setQrCodeUrl(imageUrl);
            setQrCodeOrderId(result.orderId)
        } catch (e) {
            handleInitPaymentError(e)
        }
        setProcessing(false);
    }

    if (qrCodeOrder?.state === OrderState.PAID) {
        return <Navigate to={getRestaurantPath(restaurant, `/${CUSTOMER_NAV.CONFIRM}/${qrCodeOrderId}`)} />
    }


    if (qrCodeUrl) {
        return <img className="qr-code" src={qrCodeUrl} alt={text.swish} />
    }

    const SPINNER_SIZE = '3rem';
    return <div className='center flex-column'>

        {!isPublicTerminal && <button className="btn btn-swish border mb-3" onClick={handleSwish}
            disabled={processing}
        >
            {processing ? <div className="spinner-grow" style={{ width: SPINNER_SIZE, height: SPINNER_SIZE }} /> : <img src='/assets/swish-logo.svg' alt={text.swish} />}
        </button>}
        <button className="btn btn-secondary" onClick={handleSwishQR}>
            <i className="bi bi-qr-code" /> {text.swishOnOtherDevice}
        </button>
    </div>

}
