import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Collections } from "../constants/firestore";
import { useDB } from "../contexts/FirebaseContext";
import { useRestaurant } from "../contexts/RestaurantContext";
import { useText } from "../contexts/TextContext";
import Product, { ProductState } from "../models/Product";
import { fromFirebaseDocs } from "../utils/shared/firebase";

interface ProductsHolder {
    categories: string[],
    productsByCategory: Record<string, Product[]>
    archivedProducts: Product[]
    allProducts: Product[],
}

const useProducts = (): ProductsHolder => {
    const restaurant = useRestaurant();
    const [allProducts, setAllProducts] = useState<Product[]>([]);
    const [productsHolder, setProductsHolder] = useState<ProductsHolder>({
        categories: [],
        productsByCategory: {},
        archivedProducts: [],
        allProducts: [],
    });
    const db = useDB();
    const text = useText();
    useEffect(() => {
        const queryObject = query(
            collection(db, Collections.restaurants, restaurant.id, Collections.products),
            orderBy('name'));
        return onSnapshot(queryObject, (querySnapshot) => {
            const newAllProducts = fromFirebaseDocs<Product>(querySnapshot.docs);
            setAllProducts(newAllProducts);

        });
    }, [db, restaurant.id]);

    useEffect(() => {
        if (allProducts.length) {
            const activeProducts = allProducts.filter(product => product.state === ProductState.Active);
            const productsByCategory: Record<string, Product[]> = {};

            for (const product of activeProducts) {
                const category = product.category || text.products;
                if (!productsByCategory[category]) {
                    productsByCategory[category] = [];
                }

                productsByCategory[category].push(product);
            }

            const nonEmptyCategories = Object.keys(productsByCategory);
            const orderedCategories = (restaurant.categoryOrder || [])
                .filter(category => nonEmptyCategories.includes(category));
            const newCategories = nonEmptyCategories
                .filter(category => !orderedCategories.includes(category))
                .sort();
            const categories = orderedCategories.concat(newCategories);

            // sort products in each category
            for (const category of nonEmptyCategories) {
                productsByCategory[category].sort((a, b) => (a.order || 9000) - (b.order || 9000));
            }


            const newProductsHolder: ProductsHolder = {
                categories,
                productsByCategory,
                archivedProducts: allProducts.filter(product => product.state === ProductState.Archived),
                allProducts
            }

            setProductsHolder(newProductsHolder);
        }
    }, [allProducts, restaurant.categoryOrder, text.products])


    return productsHolder;
}

export default useProducts;