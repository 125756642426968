import { doc, setDoc } from 'firebase/firestore';
import { Dropdown } from 'react-bootstrap';
import { STAFF, STAFF_NAV, STAFF_SUBNAVIGATION } from '../constants/routes';
import { useAuth } from '../contexts/AuthContext';
import { useDB } from '../contexts/FirebaseContext';
import { useSaveToast } from '../contexts/SaveToastContext';
import { useSettings } from '../contexts/SettingsContext';
import { useText } from '../contexts/TextContext'
import useConfirmModal from '../hooks/useConfirmModal';
import { Language } from '../models/Settings';
import RestaurantNavLink from './RestaurantNavLink';
import { ThreeLinesToggle } from './ThreeDotsToggle';
import { useRestaurant } from '../contexts/RestaurantContext';
import { SYSTEM_NAME, SYSTEM_VERSION } from '../constants/kassaregister';

export default function StaffMenu() {
    const text = useText();
    const { logout } = useAuth();
    const { settings, updateSettings } = useSettings();
    const [ConfirmModal, confirm] = useConfirmModal(text.busyDescription);
    const { markSaved, startSaving } = useSaveToast();
    const db = useDB();
    const restaurant = useRestaurant();
    const path = window.location.pathname;


    const toggleLanguage = () => {
        const newLanguage: Language = settings.language === 'english' ? 'swedish' : 'english';
        updateSettings({ ...settings, language: newLanguage })
    }


    const renderSubmenu = () => {
        if (path.includes(`/${STAFF}/${STAFF_NAV.RESTAURANT}`)) {
            return <div>
                <hr className='text-primary' />
                <div className="d-flex">
                    <RestaurantNavLink to={`/${STAFF}/${STAFF_NAV.RESTAURANT}`}>
                        <div className="nav-sublink text-primary">{text.openingHours}</div>
                    </RestaurantNavLink>
                    <RestaurantNavLink to={`/${STAFF}/${STAFF_NAV.RESTAURANT}/${STAFF_SUBNAVIGATION.RESTAURANT_FEATURES}`}>
                        <div className="nav-sublink text-primary">{text.features}</div>
                    </RestaurantNavLink>
                    <RestaurantNavLink to={`/${STAFF}/${STAFF_NAV.RESTAURANT}/${STAFF_SUBNAVIGATION.RESTAURANT_BRANDING}`}>
                        <div className="nav-sublink text-primary">{text.branding}</div>
                    </RestaurantNavLink>
                    <RestaurantNavLink to={`/${STAFF}/${STAFF_NAV.RESTAURANT}/${STAFF_SUBNAVIGATION.RESTAURANT_DEVICE}`}>
                        <div className="nav-sublink text-primary">{text.device}</div>
                    </RestaurantNavLink>
                    <RestaurantNavLink to={`/${STAFF}/${STAFF_NAV.RESTAURANT}/${STAFF_SUBNAVIGATION.RESTAURANT_ACCOUNTING}`}>
                        <div className="nav-sublink text-primary">{text.accounting}</div>
                    </RestaurantNavLink>

                </div>
            </div>
        }
        if (path.includes(`/${STAFF}/${STAFF_NAV.ORDERS}`)) {
            return <div>
                <hr className='text-primary' />
                <div className="d-flex">
                    <RestaurantNavLink to={`/${STAFF}/${STAFF_NAV.ORDERS}`}>
                        <div className="nav-sublink text-primary">{text.history}</div>
                    </RestaurantNavLink>
                    <RestaurantNavLink to={`/${STAFF}/${STAFF_NAV.ORDERS}/${STAFF_SUBNAVIGATION.ORDERS_DAY_REPORTS}`}>
                        <div className="nav-sublink text-primary">{text.dayReports}</div>
                    </RestaurantNavLink>
                    <RestaurantNavLink to={`/${STAFF}/${STAFF_NAV.ORDERS}/${STAFF_SUBNAVIGATION.ORDERS_ANALYTICS}`}>
                        <div className="nav-sublink text-primary">{text.analytics}</div>
                    </RestaurantNavLink>
                    <RestaurantNavLink to={`/${STAFF}/${STAFF_NAV.ORDERS}/${STAFF_SUBNAVIGATION.ORDERS_FEEDBACK}`}>
                        <div className="nav-sublink text-primary">{text.feedback}</div>
                    </RestaurantNavLink>

                </div>
            </div>
        }
        return <></>
    }

    const markBusy = () => {
        confirm(async () => {
            const saveId = startSaving();
            const newBusy = new Date();
            newBusy.setMinutes(newBusy.getMinutes() + 25);

            await setDoc(doc(db, 'restaurants', restaurant.id), { busyUntil: newBusy }, { merge: true });
            markSaved(saveId)
        })
    };

    return (
        <div className="menu">
            <div className='d-flex'>
                <div className="d-flex ">
                    <RestaurantNavLink to={`/${STAFF}`}>
                        <div className="nav-link text-primary">{text.dashboard}</div>
                    </RestaurantNavLink>
                    <RestaurantNavLink to={`/${STAFF}/${STAFF_NAV.RESTAURANT}`}>
                        <div className="nav-link text-primary">{text.restaurant}</div>
                    </RestaurantNavLink>
                    <RestaurantNavLink to={`/${STAFF}/${STAFF_NAV.PRODUCTS}`}>
                        <div className="nav-link text-primary">{text.products}</div>
                    </RestaurantNavLink>
                    <RestaurantNavLink to={`/${STAFF}/${STAFF_NAV.DEALS}`}>
                        <div className="nav-link text-primary">{text.deals}</div>
                    </RestaurantNavLink>
                    <RestaurantNavLink to={`/${STAFF}/${STAFF_NAV.ORDERS}`}>
                        <div className="nav-link text-primary">{text.orders}</div>
                    </RestaurantNavLink>
                    <RestaurantNavLink to={`/${STAFF}/${STAFF_NAV.AUDIT_LOG}`}>
                        <div className="nav-link text-primary">{text.auditLog}</div>
                    </RestaurantNavLink>
                </div>
                <div className='ms-auto'>
                    <Dropdown className='dropdown-left'>
                        <Dropdown.Toggle as={ThreeLinesToggle} className="text-primary h4 m-0" />
                        <Dropdown.Menu>

                            <Dropdown.Item className="" onClick={() => toggleLanguage()}>
                                <i className='bi bi-translate me-2' />{text.otherLanguage}
                            </Dropdown.Item>
                            <Dropdown.Item className="" onClick={() => markBusy()}>
                                <i className="bi bi-sign-stop-fill me-2" />{text.busy}
                            </Dropdown.Item>


                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => logout()}>
                                <i className="bi bi-box-arrow-right me-2" />{text.logout}
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item className="" disabled={true}>
                                {SYSTEM_NAME} {SYSTEM_VERSION}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            {renderSubmenu()}
            <ConfirmModal />
        </div>
    )
}