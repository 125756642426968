import Menu from '../../components/customer/Menu'
import { printAmount } from '../../utils/shared/converters';
import { useText } from '../../contexts/TextContext';
import CheckoutFooter from '../../components/customer/CheckoutFooter';
import ProductImg from '../../components/ProductImg';
import { areAllMandatoryChoosen, getProductDefaults } from '../../utils/shared/cart';
import useProducts from '../../hooks/useProducts';
import RestaurantLink from '../../components/RestaurantLink';
import { STAFF_NAV } from '../../constants/routes';
import { useCart } from '../../contexts/CartContext';
import Product from '../../models/Product';
import CompanyFooter from '../../components/customer/CompanyFooter';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import OpenOnTheEdge from '../../components/OpenOnTheEdge';
import RichEditor from '../../components/RichEditor';

export default function ProductsPage() {
    const text = useText();
    const productsHolder = useProducts();
    const { cart, adjustCount } = useCart();
    const [collapsedCategories, setCollapsedCategories] = useState<Record<string, boolean>>({})
    const [openProducts, setOpenProducts] = useState<Record<string, boolean>>({})
    const [imageOfProduct, setImageOfProduct] = useState<Product | null>(null);

    const addToCart = (product: Product) => {
        const defaults = getProductDefaults(product);
        adjustCount(+1, product, defaults);
    }

    const toggleCategory = (category: string) => {
        setCollapsedCategories(prev => ({ ...prev, [category]: !prev[category] }))
    }

    const toggleOpen = (product: string) => {
        setOpenProducts(prev => ({ ...prev, [product]: !prev[product] }))
    }

    return (
        <div className='streach'>
            <Menu noBack={true} />
            <div className="container">
                {productsHolder.categories?.map(category => {
                    const isCollapsed = collapsedCategories[category] ?? false;
                    return <div key={category}>
                        <div className='mt-3 d-flex pointer' onClick={() => toggleCategory(category)}>
                            <i className={`me-2 sbi  text-primary ${isCollapsed ? 'bi-caret-right-fill' : 'bi-caret-down-fill'}`} />
                            <h5 className='m-0 text-primary'>{category}</h5>
                        </div>
                        {!isCollapsed && <div className='d-flex flex-wrap'>
                            {productsHolder.productsByCategory[category]?.map(product => {
                                const defaults = getProductDefaults(product);
                                const choiceNotRequred = areAllMandatoryChoosen(product, defaults);
                                const optionsClassNames = `btn flex-1 ${choiceNotRequred ? 'btn-outline-success' : 'btn-success'}`;

                                return <div className="tile-product" key={product.id}>
                                    <div className="inner flex-column position-relative">
                                        <div className='d-flex w-100 align-items-center'>
                                            <div onClick={() => setImageOfProduct(product)}>
                                                <ProductImg product={product} type="preview" />
                                            </div>
                                            <div className="d-flex flex-column flex-grow-1 m-2">
                                                <div className="d-flex justify-content-between">
                                                    <h4 className="m-0">{product.name}</h4>
                                                    <h4 className="m-0">{printAmount(product.basePrice)}</h4>
                                                </div>
                                                <div className="d-flex">
                                                    <RestaurantLink to={`/${STAFF_NAV.PRODUCTS}/${product.id}`} className="flex-1 d-flex me-1">
                                                        <button className={optionsClassNames}>
                                                            {text.options}
                                                        </button>
                                                    </RestaurantLink>
                                                    {choiceNotRequred &&
                                                        <button className="btn btn-success flex-1 ms-1" onClick={() => addToCart(product)}>
                                                            {text.addToCart}
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {openProducts[product.id] && <div className="m-2">
                                            <RichEditor readOnly={true} raw={product.description} />
                                        </div>}
                                        <OpenOnTheEdge open={openProducts[product.id] || false} onClick={() => { toggleOpen(product.id) }} />

                                    </div>
                                </div>
                            })}
                        </div>}

                        <Modal centered show={!!imageOfProduct} className="modal fade" size='lg' onHide={() => setImageOfProduct(null)}>
                            {imageOfProduct && <div onClick={() => setImageOfProduct(null)}>
                                <ProductImg product={imageOfProduct} type="detail" />
                            </div>}

                        </Modal>
                    </div>
                })}
            </div>
            <CheckoutFooter cart={cart} />
            <CompanyFooter />
        </div>
    )
}
