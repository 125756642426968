import { useEffect, useState } from 'react'
import { CHECKOUT_CUSTOM_TEXT, FEATURE } from '../../constants/features';
import { CUSTOMER_NAV } from '../../constants/routes';
import { useCart } from '../../contexts/CartContext';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { useText } from '../../contexts/TextContext';
import { useBackend } from '../../hooks/useBackend';
import RestaurantLink from '../RestaurantLink';
import RichEditor from '../RichEditor';
import cardPhysicalLogo from '../../assets/cardPhysical.jpg';
import useOpenState from '../../hooks/useOpenState';
import NeonText from './NeonText';
import { usePublicTerminal } from '../../contexts/PublicTerminalContext';
import { PAYMENT_TYPES } from '../../utils/shared/constants';
import CheckoutPaymentSwish from './CheckoutPaymentSwish';
import useQueryParam from '../../hooks/useQueryParams';
import { getRestaurantPath } from '../../utils/restaurant';


interface PaymentDetails {
    text: string
    logo: JSX.Element
}

const existingPayments: Record<string, PaymentDetails> = {
    swish: {
        text: 'Swish',
        logo: <img className='paymentLogoSmall' src={'/assets/swish-logo.svg'} alt="swish" />
    },
    worldline: {
        text: 'Card',
        logo: <img className='paymentLogoSmall' src='/assets/card.svg' alt="card" />
    },
    card: {
        text: 'Card (physical)',
        logo: <img className='paymentLogoSmall' src={cardPhysicalLogo} alt="card" />
    },
    stripeTerminal: {
        text: 'Card (physical)',
        logo: <img className='paymentLogoSmall' src={cardPhysicalLogo} alt="card" />

    }
}

export default function CheckoutPayments() {
    const text = useText();
    const restaurant = useRestaurant();
    const { isOpen } = useOpenState();
    const [enabledPayments, setEnabledPayments] = useState([PAYMENT_TYPES.SWISH]);
    const [choosenPayment, setChoosenPayment] = useState('');
    const [customText, setCustomText] = useState({ required: false, confirmed: false, show: false })
    const [processing, setProcessing] = useState(false);
    const { initPayment, handleInitPaymentError } = useBackend();
    const { cart } = useCart();
    const { isPublicTerminal } = usePublicTerminal();
    const params = useQueryParam();


    useEffect(() => {
        if (restaurant.features?.[FEATURE.ENABLED_PAYMENTS]) {
            let enabledPayments = restaurant.features?.[FEATURE.ENABLED_PAYMENTS] as PAYMENT_TYPES[];

            if (!isPublicTerminal) {
                enabledPayments = enabledPayments.filter((paymentType) => paymentType !== PAYMENT_TYPES.CARD);
            }

            setEnabledPayments(enabledPayments)
            setChoosenPayment(enabledPayments[0])
        } else {
            setChoosenPayment(PAYMENT_TYPES.SWISH)
        }

        if (restaurant.features?.[FEATURE.CHECKOUT_CUSTOM_TEXT] === CHECKOUT_CUSTOM_TEXT.TEXT_WITH_CONFIRM || restaurant.features?.[FEATURE.CHECKOUT_CUSTOM_TEXT] === CHECKOUT_CUSTOM_TEXT.TEXT_ONLY) {
            const required = restaurant.features?.[FEATURE.CHECKOUT_CUSTOM_TEXT] === CHECKOUT_CUSTOM_TEXT.TEXT_WITH_CONFIRM;
            setCustomText({ confirmed: false, required, show: true });
        } else {
            setCustomText({ confirmed: false, required: false, show: false });
        }

    }, [isPublicTerminal, restaurant])

    const confirmCustomText = () => {
        setCustomText({ ...customText, confirmed: true });
    }


    const handleCard = async (type: PAYMENT_TYPES) => {
        setProcessing(true);
        try {
            const result = await initPayment(cart, restaurant.sysid, type);
            let redirectUrl = result.redirectUrl;
            if (!redirectUrl) {
                redirectUrl = getRestaurantPath(restaurant, `/card/${result.orderId}`)
            }

            window.location.href = redirectUrl;
        } catch (e) {
            handleInitPaymentError(e)
        }
        setProcessing(false);
    }


    const renderPaymentMethodContent = (type: string, active: boolean): JSX.Element => {
        switch (type) {
            case PAYMENT_TYPES.SWISH:
                return <div className={`center flex-column paymentTypeContent ${active && 'active'}`}>
                    <CheckoutPaymentSwish />
                </div>
            case PAYMENT_TYPES.WORLDLINE:
            case PAYMENT_TYPES.CARD:
            case PAYMENT_TYPES.STRIPE_TERMINAL:
                return <div className={`paymentTypeContent ${active && 'active'} center`}>
                    <button className="btn btn-swish border" onClick={() => handleCard(type)} disabled={processing}>
                        <span className='me-2'>
                            {processing
                                ? <span className="spinner-grow spinner-grow-sm" />
                                : <i className='bi bi-credit-card' />}
                        </span>
                        {text.payByCard}
                    </button>
                </div>
            default:
                return <></>
        }
    }
    const renderPaymentMethod = (type: string): JSX.Element => {
        const paymentTypeDetails = existingPayments[type];
        if (!paymentTypeDetails) {
            return <></>
        }

        const disableAll = (customText.required && !customText.confirmed);
        const active = !disableAll && choosenPayment === type;
        const disabled = disableAll || (processing && !active)
        return <div key={type}>
            <div className={`d-flex flex-wrap mt-4 p-1 ${disabled || processing ? '' : 'pointer'}`} onClick={() => setChoosenPayment(type)}>
                <input type="radio" className='form-check-input' name="paymentType" id={type} checked={active}
                    disabled={disabled}
                    onChange={() => setChoosenPayment(type)}
                />
                <div className='ms-3'>{paymentTypeDetails.text}</div>
                {paymentTypeDetails.logo}
            </div>
            {renderPaymentMethodContent(type, active)}
        </div>
    }


    if (restaurant.closed && !params.debug) {
        return <RichEditor readOnly={true} raw={restaurant.closedMessage} />
    }

    if (!isOpen && !cart.pickupDate && !params.debug) {
        return <>
            <NeonText text={text.closed + ' (◔_◔)'} className="mt-5" />
            <p className='center mt-5'>{text.preorderNow}</p>
        </>
    }

    return <div className='mt-5'>
        {customText.show && <>
            <RichEditor readOnly={true} raw={restaurant.features?.[FEATURE.CHECKOUT_CUSTOM_TEXT_VALUE]} />
            {customText.required && !customText.confirmed && <button className='btn btn-primary' onClick={confirmCustomText}>{text.okey}</button>}
        </>}


        <div className='d-flex flex-wrap center  mt-2'>
            <small>{text.byProceeding}</small>
            <small className='bold ms-1'><RestaurantLink to={`/${CUSTOMER_NAV.TERMS}`}>{text.termsConditions}</RestaurantLink></small>
            <small className='ms-1'>{text.and}</small>
            <small className='bold ms-1'><RestaurantLink to={`/${CUSTOMER_NAV.PRIVACY}`}>{text.privacyPolicy}</RestaurantLink></small>
        </div>
        <div className="d-flex flex-column border rounded mt-3 p-2">
            {enabledPayments.map(renderPaymentMethod)}
        </div>
    </div>
}
