import React, { useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { useCart } from '../../contexts/CartContext'
import { useRestaurant } from '../../contexts/RestaurantContext'
import { useText } from '../../contexts/TextContext'
import { dateAtTime, dateTimeToString, formatTime, dateTimeToUnixSting } from '../../utils/shared/converters'
import "react-datepicker/dist/react-datepicker.css";

interface Props {
    pickupDate: Date | null,
    updatePickupDate: (date: Date | null) => void
    className?: string
}

type ScheduleState = 'closed' | 'open';
const MAX_DAYS_IN_ADVANCE = 30 * 12;
const TIME_SLOT_INCREMENT = 15;
const MINIMUM_IN_ADVANCE = 60;

export default function SchedulePickup(props: Props) {
    const text = useText();
    const { cart, updatePickupDate } = useCart();
    const [state, setState] = useState<ScheduleState>('closed')
    const [day, setDay] = useState<Date | null>(null);
    const [dayTime, setDayTime] = useState<string>('');
    const restaurant = useRestaurant();
    const pickupDate = cart.pickupDate;

    const findTimeSlots = (date: Date): Date[] => {
        const timeSlots: Date[] = [];
        const day = date.getDay();
        const openingHoursSegments = restaurant.openingHours?.[day] || [];

        const soonest = new Date();
        soonest.setMinutes(soonest.getMinutes() + MINIMUM_IN_ADVANCE);
        for (const segment of openingHoursSegments) {
            const currentTime = dateAtTime(date, segment.start);
            currentTime.setSeconds(0);
            const endTime = dateAtTime(date, segment.end);
            currentTime.setMinutes(currentTime.getMinutes() + TIME_SLOT_INCREMENT);

            while (currentTime <= endTime) {
                if (currentTime >= soonest) {
                    timeSlots.push(new Date(currentTime));
                }
                currentTime.setMinutes(currentTime.getMinutes() + TIME_SLOT_INCREMENT);
            }
        }


        return timeSlots;
    }

    const cancel = () => {
        setDay(null);
        if (state === 'closed') {
            updatePickupDate(null)
        } else {
            setState('closed')
        }
    }

    const pickSlot = (date: Date | null) => {
        if (date) {
            updatePickupDate(date);
            cancel();
        }
    }


    const dayTimeSlots = day ? findTimeSlots(day) : [];
    const startDate = new Date()
    const endDate = new Date()
    endDate.setDate(endDate.getDate() + MAX_DAYS_IN_ADVANCE);

    const renderTimeSlots = () => {
        if (!day) {
            return <></>
        }

        if (dayTimeSlots.length === 0) {
            return <div className='mt-4 center'>
                <h5>{text.noTimesAvailable}</h5>
            </div>
        }

        return <div className='mt-2'>
            <label>{text.chooseTime}</label>
            <div>
                <select value={dayTime}
                    onChange={(event) => setDayTime(event.target.value)
                    }
                    className="form-select"
                >
                    {dayTimeSlots.map(time => (
                        <option key={dateTimeToUnixSting(time)} value={dateTimeToUnixSting(time)}>
                            {formatTime(time)}
                        </option>
                    ))}
                </select>
            </div>

            <button className='btn btn-outline-primary mt-4 w-100'
                onClick={() => pickSlot(dayTime ? new Date(dayTime) : dayTimeSlots[0])}>{text.confirm}</button>

        </div>
    }

    return (
        <div className={`${props.className}`}>
            <div className='center justify-content-start'>
                <div className='me-2'>{text.pickup}</div>
                <div className='bold me-3'>{pickupDate ? dateTimeToString(pickupDate) : text.asap}</div>
                <div className="btn-group" role="group" >
                    {state === 'closed' && <button className='btn btn-secondary' onClick={() => setState('open')}>
                        <i className="bi bi-calendar3" />
                        <div className='beta'>{text.beta}</div>
                    </button>}
                    {(state === 'open' || pickupDate) && <button className='btn btn-outline-secondary'
                        onClick={() => cancel()}>
                        <i className="bi bi-x" />
                    </button>}
                </div>
            </div>
            {state === 'open' && <div className='border mt-1 rounded p-2'>
                <label>{text.chooseDate}</label>
                <ReactDatePicker
                    className='form-control'
                    selected={day}
                    onChange={(date) => setDay(date)}
                    minDate={startDate}
                    maxDate={endDate}
                />
                {renderTimeSlots()}
            </div>}
        </div >
    )
}
