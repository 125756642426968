import { useEffect, useState } from 'react'
import { CUSTOMER_NAV } from '../../constants/routes';
import { useText } from '../../contexts/TextContext';
import { Cart } from '../../models/Cart';
import { sumCart } from '../../utils/shared/cart';
import { printAmount } from '../../utils/shared/converters';
import RestaurantLink from '../RestaurantLink';
interface Props {
    cart: Cart
}

export default function CheckoutFooter(props: Props) {
    const [extraClass, setExtraClass] = useState('')
    const text = useText();
    useEffect(() => {
        const isEmpty = !props.cart?.items?.length;
        // We are adding class after first render to make animation kick in
        setExtraClass(isEmpty ? '' : 'show');
    }, [props.cart])
    const { sum } = sumCart(props.cart);

    return (
        <div className={`footer-spacer ${extraClass}`}>
            <div className={`center footer checkout-footer ${extraClass}`}>
                <RestaurantLink to={`/${CUSTOMER_NAV.ORDER}`}>
                    <button className="btn btn-light">
                        <i className="bi bi-bag-heart-fill me-2" /> {text.checkout} {printAmount(sum)}
                    </button>
                </RestaurantLink>

            </div>
        </div>
    )
}
